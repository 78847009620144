import {ExtTranslationKeys} from './ext-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const ITExtTranslations: ExtTranslationKeys = {
    RGI_PORTAL_COMPONENTS: {
        INPUT_DOCUMENTS: 'DOCUMENTI DI INPUT',
        ADD: 'Aggiungi',
        ADD_DOCUMENT: 'Aggiungi documento',
        CANCEL: 'Cancella',
        CODE: 'Codice',
        CONFIRM: 'Conferma',
        DELETE_DOC: 'Cancella documento',
        DOCUMENTS_MANAGER: 'Gestione documenti',
        DOCUMENT_TYPE: 'Tipo documento',
        DOCUMENT_VALIDATION_MESSAGE: 'Conferma documenti?',
        DOWNLOAD: 'Download',
        EDIT: 'Edit',
        FILE: 'File',
        LOAD: 'Load',
        NO: 'No',
        NOTE: 'Note',
        NO_FILES: 'No files',
        NUMBER_OF_FILES_INSERTED: '{{ filesLength }} di {{ fileMax }} files inseriti',
        OUTPUT_DOCUMENTS: 'DOCUMENTI DI OUTPUT',
        SAVE: 'Salva',
        STATE: 'Stato',
        UPDATE_FILE: 'Update file',
        UPLOAD: 'UPLOAD',
        UPLOAD_NEW_DOCUMENT: 'Carica nuovo documen0',
        VALIDATION: 'Validazione',
        VALIDATION_DOCUMENT: 'Validazione dei documenti',
        YES: 'Yes'
    },
    _ANAG_: {
        _MSG_: {
            _USER_ON_DIFFERENT_NODE_: 'Cliente non disponibile. Utilizzare Genernet',
            TAX_ID_DIFFERENT_FROM_DOCUMENT_ID: 'Il documento di identità inserito nella sezione documenti non coincide con il documento di identità inserito nella sezione Dati di identificazione'
        }
    },
    EXT_COMPONENTS: {
        _CLAIMS_HISTORY_: {
            _LABEL_: {
                _INITIAL_BM_LEVEL_: 'Livello BM iniziale',
                _ACTUAL_BM_LEVEL_: 'Livello BM attuale',
                _ORIGIN_: 'Origine',
                _HISTORY_MATRIX_: 'Matrice sinistri',
                _MTPL_: 'Responsabilità civile',
                _MOD_: 'Danni propri',
                _YEARS_: 'Anni',
                _CLAIMS_: 'Sinistri',
                _CLAIMS_PERC: '% Sinistrosità',
                _COEFF_: 'Coefficiente',
                _POLICY_NUMBER_: 'Numero polizza',
                _INCEPTION_DATE_: 'Data emissione',
                _CANC_DATE_: 'Data annullo',
                _LICENSE_PLATE_: 'Targa',
                _VEHIC_TYPE_: 'Tipo Veicolo',
                _DATE_: 'Data',
                _MATERIAL_: 'Danni materiali',
                _BODILY_: 'Danni alle persone',
                _CALCULATION_DATE_: 'Data chiamata'
            }
        },
        _DEEP_LINK_: {
            _NOT_FOUND_: 'Non trovato',
            _CLOSE_: 'Chiudi'
        }
    },
    _CLAIMS_: {
        _CAUSE: 'Causa'
    },
    RGI_SHARED_COMPONENTS:{
        CLOSE: 'Chiudi',
        DOWNLOAD: 'Scaricamento',
        DESCRIPTION: 'Descrizione',
        STATE: 'Stato',
        SIGN: 'Cartello',
        SEND: 'Inviare',
        ARCHIVE: 'Archivio',
        OUTBOUND_DOC_TITLE: 'Documenti politici n. {{ policyNumber }} di movimento {{ movementDesc }}',
        OUTBOUND_DOC_PROPOSAL_TITLE: 'Documenti di proposta n. {{ proposalNumber }} di movimento {{ movementDesc }}',
        TO_BE_GENERATED: 'da generare',
        ARCHIVE_DOC: 'Documenti archivo',
        DELIVERY_DOC: 'Documento de entrega',
        DOWNLOAD_DOC: 'Scarica documento',
        TYPE: 'Tipo',
        FILE: 'File',
        UPLOADED: 'Caricato',
        DELIVERY_PARTY: 'Soggetto invio',
        CREATED: 'Creato',
        UPLOADING_USER: 'Utente caricamento',
    },
    _MIC_:{
        _VEHICLE_ORIGIN_: 'Storia sinistri'
      }
}
