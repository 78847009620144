import { Injectable } from '@angular/core';
import {OperatorService} from '@rgi/portal-ng-core';

@Injectable({
  providedIn: 'root'
})
export class PortalOperatorService extends OperatorService {

  private userCode: string;
  private salePointLogin: any;
  private salePointDefault: any;
  private enabledFeatures: string[];
  private directionalUser: boolean;

  constructor() {
    super();
  }

  setUserCode(userCode: string) {
    this.userCode = userCode;
  }

  setSalePointLogin(salePointLogin: any) {
    this.salePointLogin = salePointLogin;
  }

  setEnabledFeatures(auths: string[]) {
    this.enabledFeatures = auths;
  }

  getUserCode(): string {
    return this.userCode;
  }

  getSalePointLogin(): any {
    return this.salePointLogin;
  }

  getEnabledFeatures(): string[] {
    return this.enabledFeatures;
  }

  setSalePointDefault(salePointDefault: any) {
    this.salePointDefault = salePointDefault;
  }

  getSalePointDefault(): any {
    return this.salePointDefault;
  }

  isDirectionalUser(): boolean {
    return this.directionalUser;
  }

  setDirectionalUser(isDirectional: boolean) {
    this.directionalUser = isDirectional;
  }

}
