import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { downgradeComponent, UpgradeModule } from '@angular/upgrade/static';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from './utilities.service';
import { LoggingMissingTranslationHandler, MergeTranslationsLoader } from './translations-config';
import { OperatorService, PortalNgCoreModule } from '@rgi/portal-ng-core';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { PortalOperatorService } from './portal-operator.service';

import { LifeModule } from './life/life.module';
import { MotorModule } from './motor/motor.module';


import { RGI_RX_PASSPORTAL_INTERCEPTORS, RgiRxPortalModule } from '@rgi/rx/portal';
import { RGI_RX_HTTP_INTERCEPTORS, RgiRxHttpModule } from '@rgi/rx/http';
import { RGI_RX_AUTH_HTTP_INTERCEPTORS, RgiRxAuthModule } from '@rgi/rx/auth';
import { AnalyticsPortalModule } from '@rgi/analytics/portal';
import { AdapterPrintPortalModule } from '@rgi/adapterprint-card/portal';
import { ReIssueCardPortalModule } from '@rgi/re-issue-card/portal';
import { ErrorManagementService, ReIssueCoreModule } from '@rgi/re-issue-card';

import { IcdCardPortalModule } from "@rgi/icd-card/portal";
import { RgiPncPostsalesPortalModule } from '@rgi/pnc-postsales/portal';
import { GroupPolicyPortalModule } from '@rgi/group-policy/portal';
import { verifyInterceptors } from './verify-interceptors';
import { PROJECT_HTTP_INTERCEPTORS } from './interceptors';
import { ClaimsModule } from './claims/claims.module';
import { PORTAL_AJS_PROVIDERS } from './ajs-upgraded-providers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnagModule } from './ext/anag/anag.module';
import { SurveyModule } from './survey/survey.module';
import { RgiRxExpansionModule, RgiRxNativeDateTimeModule, RgiRxTableModule } from '@rgi/rx/ui';
import { APP_CONFIG_PROVIDERS } from './app.config';
import { ContractConsultationPortalModule } from "@rgi/contract-consultation-card/portal";
import { ContractConsultationModule } from "@rgi/contract-consultation-card";
import { RgiPncPsalesFlowsModule } from '@rgi/pnc-postsales';
import { RgiRxI18nModule, RgiRxTranslationService } from '@rgi/rx/i18n';
import {
  AccountingTransactionsComponent,
  ClaimDocumentsModule,
  ExternalPortfolioExtrainfoComponent,
  ExternalPortfolioExtrainfoModule,
  FeeDataModalComponent,
  ReassignTaskComponent
} from '@rgi/digital-claims-angular';
import { environment, openIdConfig, rgiRxAuthConfig } from '../environments/environment';
import {
  AdminModule,
  OpenIdModule,
  RgiAuthBrowserLocalStoreModule,
  RgiAuthOpenIdTokenRefreshService,
  RgiAuthStore
} from "@rgi/iiab-gel-library";
import { AjsConfiguration } from './ajs-configuration';
import { EXT_TRANSLATIONS } from './ext/i18n/translations';
import { IIABPortalModule } from '@rgi/iiab-portal-ext/portal';
import { ClaimsHistoryComponent } from './ext/claims/claims-history/claims-history.component';
import { ClaimsHistoryModalComponent } from './ext/claims/claims-history-modal/claims-history-modal.component';
import {AuthorizationsCardPortalModule} from "@rgi/authorizations-card/portal";
import { DeepLinkModule } from './deeplink/deeplink.module';
import { PORTAL_AJS_DEEPLINK_PROVIDERS } from './deeplink/ajs-upgrade-deeplink-providers';
import {AuthorizationsModule} from "./ext/authorizations/authorizations.module";
import { RgiPncPostsalesModuleExt } from './ext/pnc-postsales/pnc-postsales.module';
import { PncPsalesFlowsModule } from './ext/pnc-postsales/pnc-psales-flows.module';
import { OccurrenceDataModifyExtModule } from './ext/claims/occurrence-data-modify/occurrence-data-modify.module';
import { CaiOpeningExtModule } from './ext/claims/cai-opening/cai-opening.module';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UpgradeModule,
    HttpClientModule,
    RgiRxPortalModule,
    RgiRxHttpModule,
    RgiRxNativeDateTimeModule,
    RgiRxExpansionModule,
    RgiRxTableModule,
    AnagModule,
    OccurrenceDataModifyExtModule,
    AuthorizationsModule,
    SurveyModule,
    LifeModule,
    MotorModule,
    ClaimsModule,
    AnalyticsPortalModule,
    GroupPolicyPortalModule,
    PortalNgCoreModule,
    ReIssueCardPortalModule,
    ReIssueCoreModule,
    RgiPncPostsalesPortalModule,
    RgiPncPsalesFlowsModule,
    AdapterPrintPortalModule,
    IcdCardPortalModule,
    ContractConsultationPortalModule,
    ContractConsultationModule,
    ClaimDocumentsModule,
    AuthorizationsCardPortalModule,
    RgiRxAuthModule.forRoot(rgiRxAuthConfig),
    IIABPortalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MergeTranslationsLoader
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LoggingMissingTranslationHandler
      }
    }),
    ExternalPortfolioExtrainfoModule,
    OpenIdModule.forRoot(openIdConfig),
    AdminModule,
    RgiAuthBrowserLocalStoreModule,
    RgiRxI18nModule.forRoot(EXT_TRANSLATIONS),
    DeepLinkModule,
    RgiPncPostsalesModuleExt,
    PncPsalesFlowsModule,
    CaiOpeningExtModule
  ],
  providers: [
    ...APP_CONFIG_PROVIDERS,
    ...PORTAL_AJS_PROVIDERS,
    PORTAL_AJS_DEEPLINK_PROVIDERS,
    UtilitiesService,
    DynamicScriptLoaderService,
    PortalOperatorService,
    { provide: OperatorService, useExisting: PortalOperatorService },
    RGI_RX_HTTP_INTERCEPTORS,
    RGI_RX_AUTH_HTTP_INTERCEPTORS,
    RGI_RX_PASSPORTAL_INTERCEPTORS,
    PROJECT_HTTP_INTERCEPTORS,
    RgiAuthOpenIdTokenRefreshService,
    { provide: 'showEntryPointDateOfBirth', useValue: false },
    { provide: ErrorManagementService, useClass: ErrorManagementService },
    { provide: 'RGI_ANAG_PORTAL_AJS_FLOW', useValue: false },
    { provide: 'RGI_POSTSALES_CANCELLATION_OVERRIDE', useValue: false },
    { provide: 'environment', useValue: environment },
    { provide: 'RGI_POSTSALES_OPEN_OLD_AJS_CONSULTATION', useValue: true }

  ],
  bootstrap: [],
  declarations: [
    ClaimsHistoryComponent,
    ClaimsHistoryModalComponent
  ]
})
export class AppModule implements DoBootstrap {
  private translate: TranslateService;
  constructor(
    private upgrade: UpgradeModule,
    translate: TranslateService,
    private dynamicLoader: DynamicScriptLoaderService,
    private operatorService: PortalOperatorService,
    private injector: Injector,
    private rxTranslationsService: RgiRxTranslationService,
    store: RgiAuthStore
  ) {
    this.translate = translate;
    rxTranslationsService.setCurrentLanguage(this.translate.currentLang);
    console.log('app constructor');

    store.getState$().subscribe(() => {
      angular.module('app')
        .run(['coreAuthService', (coreAuthService) => {
          coreAuthService.setToken(store.snapshot.token.id_token);
        }]);
    });
  }

  ngDoBootstrap() {
    AjsConfiguration();
    this.dynamicLoader.load('environment').then(() => {
      // Script Loaded Successfully
      this.upgrade.bootstrap(document.body, ['app'], { strictDi: true });
      verifyInterceptors(this.injector.get(HTTP_INTERCEPTORS));
      const coreLang = this.upgrade.$injector.get('coreLang');
      const eventService = this.upgrade.$injector.get('eventService');
      const catalog = this.upgrade.$injector.get('gettextCatalog');

      MergeTranslationsLoader.setCatalog(catalog.strings);
      this.updateCurrentLang(coreLang.getLang()); // default language
      eventService.listenGlobalEvent('gettextLanguageChanged', () => this.updateCurrentLang(coreLang.getLang()));
      /**
       * @deprecated block. RgiRxAuth should be used to retrieve the operator
       */
      eventService.listenGlobalEvent('node-completed', () => {
        const authService = this.upgrade.$injector.get('authService');

        const operator = authService.getOperator();
        if (operator) {
          this.operatorService.setUserCode(operator.username);
          this.operatorService.setSalePointLogin(operator.salePoint);
          this.operatorService.setSalePointDefault(authService.getSalePointDefault());
          this.operatorService.setDirectionalUser(operator.isDirectionalUser);
        }
      });

    }).catch(error => console.log(error));
  }

  /**
   * @deprecated used to integrate ngx-translate with portal. To be removed with ngx-translate
   * @param lang
   */
  updateCurrentLang(lang: string) {
    for (let lang1 of this.translate.getLangs()) {
      this.translate.resetLang(lang1);
    }
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    console.log('language set to ' + lang);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-var
declare var angular: any;
angular.module('reassign-task', []).directive('reassignTask',
  downgradeComponent({ component: ReassignTaskComponent }) as angular.IDirectiveFactory);
angular.module('accounting-transactions', []).directive('accountingTransactions',
  downgradeComponent({ component: AccountingTransactionsComponent }) as angular.IDirectiveFactory);
angular.module('external-portfolio-extrainfo', []).directive('externalPortfolioExtrainfo',
  downgradeComponent({ component: ExternalPortfolioExtrainfoComponent }) as angular.IDirectiveFactory);
angular.module('modify-ndc-legal', []).directive('modifyNdcLegal',
  downgradeComponent({ component: FeeDataModalComponent }) as angular.IDirectiveFactory);
angular.module('claims-history-ext', []).directive('claimsHistoryExt',
    downgradeComponent({component: ClaimsHistoryComponent}) as angular.IDirectiveFactory);
