import {Injectable} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { AnagFormFieldConfig, AnagStatelessService, AnagConfigurationFilter, AnagStatePartyEditor, PACONF_VALUES, AnagConfigService, AnagExtensionSubject, AnagExtensionsService } from "@rgi/anag";
import { RgiRxTranslationService } from "@rgi/rx/i18n";

@Injectable({
  providedIn: 'root'
})
export class AnagExtensionsServiceExt extends AnagExtensionsService {

  constructor(
    protected statelessService: AnagStatelessService,
    protected i18nService: RgiRxTranslationService,
    protected configService: AnagConfigService,
  ) {
    super();
  }

  onChangeDocType(formMap: Map<string, AnagFormFieldConfig>, actualForm: UntypedFormGroup, statePartyEditor: AnagStatePartyEditor) {
    const configFilter = new AnagConfigurationFilter();
    configFilter.partyType = statePartyEditor.party.subjectType && statePartyEditor.party.subjectType.codice ? parseInt(statePartyEditor.party.subjectType.codice) : PACONF_VALUES.FILTER_DEFAULT;
    configFilter.country = statePartyEditor.party.partyKey && statePartyEditor.party.partyKey.length > 0 ? statePartyEditor.party.partyKey[0].country : '';
    configFilter.legalForm = PACONF_VALUES.FILTER_DEFAULT;
    configFilter.partyRole = statePartyEditor.flowData && statePartyEditor.flowData.partyRole ? statePartyEditor.flowData.partyRole : PACONF_VALUES.FILTER_DEFAULT;
    
    if (actualForm.get('documentType').value === '2') {
      configFilter.extensionSubject = new AnagExtensionSubject();
      configFilter.extensionSubject.properties = [];
      this.addExtensionProperty(configFilter.extensionSubject.properties, 'IS_PATENTE', '1');

      /** TEMPORARY FIX
       * If document type is 2 (Driving license), add validator required
       * Will be replaced by retrieving config from BE, but at the moment there are bugs related to the validators in that solution
       */
      let releaseDateControl = actualForm.get('releaseDate');
      releaseDateControl.addValidators(Validators.required);
      releaseDateControl.updateValueAndValidity();

    }
    
    //TEMPORARY COMMENTED
    // Will be implemented after the solving of the bug related to validators
    // When we call setConfigToForm, validators are overwrited and data are not validated anymore

    // this.statelessService.retrievePartyConf(this.statelessService.getFlowDataUpdatedConfFilter(configFilter, statePartyEditor.flowData)).pipe().subscribe
    //   (conf => {
    //     if (typeof conf === 'string') {
    //       if (statePartyEditor.errors.length === 0) {
    //         statePartyEditor.errors.push(conf);
    //       }
    //       statePartyEditor.blockedFlow = true;
    //     } else if (conf.error && conf.error.descrizioneErroreOccorso) {
    //       statePartyEditor.errors.push(conf.error.descrizioneErroreOccorso);
    //       statePartyEditor.blockedFlow = true;
    //     } else if (!conf.output.partyConfig) {
    //       this.i18nService.translate('_ANAG_._MSG_._WRONG_INPUT_DATA_').subscribe(tr => {
    //         statePartyEditor.errors.push(tr);
    //         statePartyEditor.blockedFlow = true;
    //       }).unsubscribe();
    //     } else {
    //       statePartyEditor.configuration = conf.output;
    //       this.configService.setConfigToForm(formMap, statePartyEditor.configuration);
    //     }
    // });
  }
}
