import {ExtTranslationKeys} from './ext-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const ESExtTranslations: ExtTranslationKeys = {
    RGI_PORTAL_COMPONENTS: {
        ADD: 'Agregar',
        ADD_DOCUMENT: 'Agregar documento',
        CANCEL: 'Borrar',
        CODE: 'Código',
        CONFIRM: 'Confirmar',
        DELETE_DOC: 'Eliminar documento',
        DOCUMENTS_MANAGER: 'Gestión de documentos',
        DOCUMENT_TYPE: 'Tipo de documento',
        DOCUMENT_VALIDATION_MESSAGE: '¿Continuar con la validación del documento?',
        DOWNLOAD: 'Descargar',
        EDIT: 'Editar',
        FILE: 'Archivo',
        INPUT_DOCUMENTS: 'documentos de entrada',
        LOAD: 'Carga',
        NO: 'No',
        NOTE: 'Nota',
        NO_FILES: 'Sin archivos',
        NUMBER_OF_FILES_INSERTED: '{{ filesLength }} de {{ fileMax }} archivos insertados',
        OUTPUT_DOCUMENTS: 'Documentos de salida',
        SAVE: 'Ahorrar',
        STATE: 'Estado',
        UPDATE_FILE: 'Editar archivo',
        UPLOAD: 'UPLOAD',
        UPLOAD_NEW_DOCUMENT: 'Subir nuevo documento',
        VALIDATION: 'Validación',
        VALIDATION_DOCUMENT: 'Validación de documentos',
        YES: 'Sí'
    },
    _ANAG_: {
        _MSG_: {
            _USER_ON_DIFFERENT_NODE_: 'Cliente no disponible. Utilizar Genernet',
            TAX_ID_DIFFERENT_FROM_DOCUMENT_ID: 'El Documento de Identidad (NIF/NIE/Pasaporte) informado en la Sección Documentos no coincide con el Documento de Identidad (NIF/NIE/Pasaporte) informado en el apartado Datos de Identificación'
        }
    },
    EXT_COMPONENTS: {
        _CLAIMS_HISTORY_: {
            _LABEL_: {
                _INITIAL_BM_LEVEL_: 'Nivel Bonus/Malus Entrada',
                _ACTUAL_BM_LEVEL_: 'Nivel Bonus/Malus Actual',
                _ORIGIN_: 'Origen',
                _HISTORY_MATRIX_: 'Matriz de historial sinistral',
                _MTPL_: 'Responsabilidad civil',
                _MOD_: 'Daños proprios',
                _YEARS_: 'Años',
                _CLAIMS_: 'Siniestros',
                _CLAIMS_PERC: '% siniestralidad',
                _COEFF_: 'Coeficiente',
                _POLICY_NUMBER_: 'Número de póliza',
                _INCEPTION_DATE_: 'Fecha de emisión',
                _CANC_DATE_: 'Fecha de cancelación',
                _LICENSE_PLATE_: 'Número de placa',
                _VEHIC_TYPE_: 'Clase vehículo',
                _DATE_: 'Fecha',
                _MATERIAL_: 'Daños materiales',
                _BODILY_: 'Daños personales',
                _CALCULATION_DATE_: 'Fecha de cálculo'
            }
        },
        _DEEP_LINK_: {
            _NOT_FOUND_: 'ES_Not found',
            _CLOSE_: 'Cerrar'
        }
    },
    _CLAIMS_: {
        _CAUSE: 'Causa'
    },
    RGI_SHARED_COMPONENTS:{
        CLOSE: 'Cerrar',
        DOWNLOAD: 'Descargar',
        DESCRIPTION: 'Descripción',
        STATE: 'Estado',
        SIGN: 'Firmar',
        SEND: 'ENVIAR',
        ARCHIVE: 'ARCHIVO',
        OUTBOUND_DOC_TITLE: 'Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
        OUTBOUND_DOC_PROPOSAL_TITLE: 'Documentos de propuesta nr. {{ proposalNumber }} de movimiento {{ movementDesc }}',
        TO_BE_GENERATED: 'para ser generado',
        ARCHIVE_DOC: 'Archivar documento',
        DELIVERY_DOC: 'Documento de entrega',
        DOWNLOAD_DOC: 'Descargar documento',
        TYPE: 'Tipo',
        FILE: 'File',
        UPLOADED: 'Cargado',
        DELIVERY_PARTY: 'Asunto de envío',
        CREATED: 'Creado',
        UPLOADING_USER: 'Cargando usuario'
      },
      _MIC_:{
        _VEHICLE_ORIGIN_: 'Historial siniestral'
      }
}
