import {deeplinkLegacyConfig} from "../../../environments/environment";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DeepLinkLegacyService {

    readonly POLICY_DEEPLINK: string = '{host}{function}&ramo={ramo}&poliza={legacyId}&ddefecto={effectiveDay}&mmefecto={effectiveMonth}&aaefecto={effectiveYear}';
    readonly PROPOSAL_DEEPLINK: string = '{host}{function}cia={cia}&yramex={ramo}&ycot={legacyId}&ynumag={nodeCode}&sigView=si&oper=926&consultaDirecta=S&resolucion=1024';
    readonly AUTHORIZATION_DEEPLINK: string = '{host}{function}&cia={cia}&ramo={ramo}&idPeticionBPM={legacyId}&idCognitiveAssistant=&tao=1&tipoTareaBPM={status}';
    readonly TRANSACTION_DEEPLINK: string = '{host}{function}&recibo={legacyId}&resolucion=1024';

    private buildDeepLink(template: string, values: { [key: string]: string }): string {
        return template.replace(/{(\w+)}/g, (match, key) => {
            return values.hasOwnProperty(key) ? values[key] : match;
        });
    }

    public gotoPolicy(legacyId: string, effectiveDate?: Date) {

        effectiveDate = effectiveDate ? new Date(effectiveDate) : new Date();
        window.open(this.buildDeepLink(this.POLICY_DEEPLINK, {
            'host': deeplinkLegacyConfig.host,
            'function': deeplinkLegacyConfig.policy.function,
            'ramo': legacyId.length ? legacyId.substring(1, 3) : 'UV', // (The second and third characters of the policy number)
            'legacyId': legacyId.length ? legacyId.substring(3) : legacyId, //GUV621000026000000 → Should be 621000026000000 (From the fourth character of the policy to the end)
            'effectiveDay': String((effectiveDate.getDate())),
            'effectiveMonth': String((effectiveDate.getMonth() + 1)),
            'effectiveYear': String((effectiveDate.getFullYear())),
        }));
    }

    public gotoProposal(legacyId: string, nodeCode: string) {

        window.open(this.buildDeepLink(this.PROPOSAL_DEEPLINK, {
            'host': deeplinkLegacyConfig.host,
            'function': deeplinkLegacyConfig.proposal.function,
            'cia': legacyId.length ? legacyId.substring(0,1) : 'G',
            'ramo': legacyId.length ? legacyId.substring(1,3) : 'HR',
            'nodeCode': nodeCode.length ? nodeCode.substring(1) : nodeCode,
            'legacyId': legacyId.length ? legacyId.substring(3) : legacyId
        }));
    }

    public gotoAuthorization(legacyId: string) {

        window.open(this.buildDeepLink(this.AUTHORIZATION_DEEPLINK, {
            'host': deeplinkLegacyConfig.host,
            'function': deeplinkLegacyConfig.authorization.function,
            'cia': legacyId.length ? legacyId.substring(0,1) : 'G',
            'ramo': legacyId.length ? legacyId.substring(1,3) : 'S7',
            'legacyId': legacyId.length ? legacyId.substring(3) : legacyId
        }));
    }

    public gotoTransactions(legacyId: string) {

        window.open(this.buildDeepLink(this.TRANSACTION_DEEPLINK, {
            'host': deeplinkLegacyConfig.host,
            'function': deeplinkLegacyConfig.transaction.function,
            'legacyId': legacyId
        }));
    }
}
