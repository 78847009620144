import { PncPsalesConfirmationStateManager, PncPostSalesOrchestratorService, PncPostSalesIntegrationService, PncPsalesHttpErrorService, PncPsalesConfirmationState, PncDomainService } from "@rgi/pnc-postsales";

import { RgiRxPushMessageHandlerService } from "@rgi/rx";
import { ActiveRoute } from "@rgi/rx/router";
import { StateStoreService } from "@rgi/rx/state";
import { Observable, of } from "rxjs";
import { concatMap, map, catchError } from "rxjs/operators";
import { PncPsalesClaimsInsuranceApiService } from "../../../resources/http/pnc-psales-claims-insurance-api.service";
import { Documents } from "../../../resources/model/api/document";
import { ModalService } from "@rgi/rx/ui";

export class PncPsalesConfirmationStateManagerClaimsInsurance extends PncPsalesConfirmationStateManager {


    constructor(
        activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        orchestrator: PncPostSalesOrchestratorService,
        integrationService: PncPostSalesIntegrationService,
        pushMessageHandler: RgiRxPushMessageHandlerService,
        errorService: PncPsalesHttpErrorService,
        context: any,
        resourceService: PncPsalesClaimsInsuranceApiService,
        resourceServicePncDomain: PncDomainService,
        protected modalService: ModalService
    ) {
        super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context, resourceServicePncDomain, modalService);

            
    }

    initState$(_state: Observable<PncPsalesConfirmationState>): Observable<PncPsalesConfirmationState> {
        return of(new PncPsalesConfirmationState(this.activeRoute)).pipe(
            concatMap((st: PncPsalesConfirmationState) => {
                return this.resourceService.getDocuments$(st.policyNumber, st.currentOperation.code).pipe(
                    map((resp: Documents) => {
                        st.documents = resp;
                        return st;
                    }),
                    catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
                );
            }),
            catchError(this.errorService.manageStreamErrFn()),
            map((st: PncPsalesConfirmationState) => st)
        );
        //return new Observable();
    }

    public downloadDocuments$(policyNumber: string, documentCode: string): Observable<Blob> {
        //return new Observable();
        return this.resourceService.downloadDocuments$(policyNumber, documentCode);
    }
}